import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/fourfourtwo.css';

loadCSS('fourfourtwo-site');

export const FourfourtwoSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default FourfourtwoSiteWrapper;
